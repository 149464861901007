import {
  SpaceDashboard,
  Apartment,
  Send,
  Person,
  QuestionMark,
  Logout,
  AssignmentOutlined,
  Home,
  Shield,
} from '@mui/icons-material';
import {
  externalConnections,
  externalLinks,
  externalTitles,
  routes,
} from '../../constants/Constants';
import { SideMenuItem } from './SideMenuItem';
import { useLocation } from 'react-router';
import { goToExternal, useNavigateWithSegment } from '../../utils/linkUtils';
import { Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import useProfileDrawerStore from '../../stores/useProfileDrawerStore';
import { useQuery } from '@tanstack/react-query';
import getUser from '../../utils/apiRestCalls/getUser';
import logout from '../../utils/axios/logout';
import { checkIfUserCanEnrollProperties, shouldDisableRenterInvite } from '../../utils/commonUtils';
import { useUrlChecker } from '../../hooks/useUrlChecker';
import { trackEvent } from '../../utils/analytics';
import { RiVisibilityGuard } from '../../features/RentersInsurance/guards';
import { RiNewBadge } from '../../features/RentersInsurance/components';

interface Props {
  menuOpen: boolean;
}

export const MenuContent = ({ menuOpen }: Props) => {
  const navigate = useNavigateWithSegment();
  const location = useLocation();
  const { showProfileDrawer } = useProfileDrawerStore();
  const { isPublic } = useUrlChecker();
  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(),
    staleTime: Infinity,
  });

  const onLogOut = () => {
    trackEvent('Logout Button is clicked', {
      title: 'Logout',
      referrer: window.location.pathname,
    });
    logout();
  };

  const onProfile = () => {
    trackEvent('Profile Button is clicked', {
      title: 'Profile',
      referrer: window.location.pathname,
    });
    showProfileDrawer();
  };

  const goToFAQ = () => {
    if (user && !checkIfUserCanEnrollProperties(user)) {
      return goToExternal(externalLinks.operatorsFAQ, {
        title: 'FAQ Menu (Operator)',
        path: externalLinks.operatorsFAQ,
      });
    }

    return goToExternal(externalLinks.faq, {
      title: 'FAQ Menu',
      path: 'enroll-property#faq',
    });
  };

  return (
    <Grid
      container
      sx={{
        height: '100%',
        pb: '8px',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'thin',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      {!isPublic && (
        <Grid item id="firstColumnIcons">
          <SideMenuItem
            open={menuOpen}
            text="Home"
            icon={<Home />}
            action={() =>
              navigate(routes.home, {
                title: 'Visit Home Menu',
                path: '/',
              })
            }
            selected={location.pathname === routes.home}
          />
          {user && (
            <SideMenuItem
              open={menuOpen}
              text="Invite Renter"
              icon={<Send />}
              action={() => navigate(routes.renterInvite)}
              selected={location.pathname.includes(routes.renterInvite)}
              disabled={shouldDisableRenterInvite(user)}
            />
          )}
          {user && checkIfUserCanEnrollProperties(user) && (
            <SideMenuItem
              open={menuOpen}
              text="Enroll Properties"
              icon={<Apartment />}
              action={() =>
                navigate(routes.propertyEnrollmentForm, {
                  title: 'Visit Enroll Properties Menu',
                  path: '/enroll/property-type',
                })
              }
              selected={location.pathname.includes(routes.propertyEnrollmentForm)}
            />
          )}
          {user && (
            <SideMenuItem
              open={menuOpen}
              text="Deals Dashboard"
              icon={<SpaceDashboard />}
              action={() => {
                if (user?.newDealsDashboardAccess) {
                  navigate(routes.dealsDashboard);
                } else {
                  goToExternal(externalConnections.dashboard, {
                    title: 'Visit Dashboard Menu',
                    path: '/v2/landlord',
                  });
                }
              }}
            />
          )}

          <RiVisibilityGuard>
            <SideMenuItem
              open={menuOpen}
              text="Renters Insurance"
              displayText={
                <Box component={'span'} display={'flex'} alignItems={'center'} columnGap={'8px'}>
                  <Typography component={'span'}>Renters Insurance</Typography>
                  <RiNewBadge />
                </Box>
              }
              icon={<Shield />}
              action={() =>
                navigate(routes.rentersInsurance, {
                  title: 'Visit Renters Insurance',
                  path: '/renters-insurance',
                })
              }
              selected={location.pathname.includes(routes.rentersInsurance)}
            />
          </RiVisibilityGuard>

          <SideMenuItem
            open={menuOpen}
            text="Submit a Claim"
            icon={<AssignmentOutlined />}
            action={() =>
              goToExternal(externalLinks.submitClaim, {
                title: 'Submit a Claim Menu',
                path: '/submit-a-claim',
              })
            }
          />
        </Grid>
      )}
      <Grid item>
        <Divider sx={{ m: '8px 0' }} />
        {!isPublic && (
          <SideMenuItem open={menuOpen} text="Profile" icon={<Person />} action={onProfile} />
        )}
        <SideMenuItem
          open={menuOpen}
          text={externalTitles.faq}
          icon={<QuestionMark />}
          action={goToFAQ}
        />
        <SideMenuItem open={menuOpen} text="Logout" icon={<Logout />} action={onLogOut} />
      </Grid>
    </Grid>
  );
};
